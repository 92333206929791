import { Button, Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { callApi } from "../../utills/api";
import { showError, showSuccess } from "../../utills/sweetAlert";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFileAlt,
  faGear,
  faMinusCircle,
  faPlusCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./login.css";
import { useEffect, useState } from "react";
import ExpenseExcelComponent from "./expencedataExcel";

interface Expenceaddrow {
  valid: boolean;
  engineer: string;
  selectsite: string;
  worktype: string;
  amount: string;
  categery: string;
  description: string;
  legal_hiers_id?: string;
}

const Expensepage = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    SiteSelect: [],
    SiteEngineerslect: [],
    WorktypeSelect: [],
    CategerySelect: [],
    ExpenseDaytable: [],
  });

  const [siteengineerDetails, setsiteengineerDetails] = useState({
    id: "",
    worktype: "",
  });

  useEffect(() => {
    getSiteengineer();
    console.log("siteengineerDetails:", siteengineerDetails);
  }, [siteengineerDetails.worktype]);

  const getSiteengineer = () => {
    callApi("POST", "siteengineer_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("engineerselect", res.data.responseJson.data);
          setState((prevState) => ({
            ...prevState,
            SiteEngineerslect: res.data.responseJson.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    callApi("POST", "site_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("site_select", res.data.responseJson.data);
          setState((prevState) => ({
            ...prevState,
            SiteSelect: res.data.responseJson.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    callApi("POST", "worktype_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("worktype_select", res.data.responseJson.data);
          setState((prevState) => ({
            ...prevState,
            WorktypeSelect: res.data.responseJson.data,
          }));
          console.log("sakthi---worktype--amount",state.WorktypeSelect);
          
        }
      })
      .catch((err) => {
        console.log(err);
      });

    callApi("POST", "categery_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("categery_select", res.data.responseJson.data);
          setState((prevState) => ({
            ...prevState,
            CategerySelect: res.data.responseJson.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [isValid, setIsValid] = useState(false);
  const [expenceData, setExpenceData] = useState([
    {
      engineer: "",
      selectsite: "",
      worktype: "",
      categery: "",
      amount: "",
      valid: false,
      description: "",
    },
  ]);

  const [expenseSelectmodal, setExpensemodal] = useState(false);


  useEffect(() => {
    console.log("expenceData has changed:", expenceData);
  }, [expenceData]);

  const onchange = (e: any, index: number) => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;
    const updatedExpenceData = [...expenceData];
    const newItem = { ...updatedExpenceData[index], [fieldName]: fieldValue };
    newItem.valid = fieldValue !== ""; 
    updatedExpenceData[index] = newItem;
    setExpenceData(updatedExpenceData);
  };

  const checkValid = () => {
    let valid = false;
    for (let i = 0; i < expenceData.length; i++) {
      const element = expenceData[i];
      if (
        element.engineer != "" &&
        element.selectsite != "" &&
        element.worktype != "" &&
        element.categery != "" &&
        element.description != ""
      ) {
        valid = true;
        element.valid = true;
      } else {
        valid = false;
        element.valid = false;
      }
    }
    return valid;
  };

  const resetExpenseData = () => {
    setExpenceData([]); 
    console.log("setExpenceData empty");
  };
  
  const SubmitHandleclick = async () => {
    console.log("handleclick");
    const isValid = await checkValid();
    console.log("checkis valid------", isValid, expenceData);
    const req = {
      Expensedata: expenceData,
    };
    console.log("req", req);
    if (isValid) {
      setIsValid(false);
      callApi("POST", "expense_insert.php", req)
        .then((res) => res.data)
        .then(async (response) => {
          console.log("response", response);
          if (response.success === true) {
            await showSuccess("Success!", "Expense Added Successfully!");
            resetExpenseData();
            Dailydata(); // No need to await here
            setTimeout(() => {
              addExpenceRow();
            }, 1000);
          } else {
            showError("Failed", "Not Inserted !");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setIsValid(true);
    }
  };
  
  const addExpenceRow = (): void => {
    const newExpence = {
      engineer: "",
      selectsite: "",
      worktype: "",
      valid: false,
      categery: "",
      amount: "",
      description: "",
    };
    setExpenceData((prevExpenceData) => [...prevExpenceData, newExpence]);
    console.log(expenceData); // This may not reflect the updated state immediately
  };
  
  const deleteExpenceRow = (index: number): void => {
    const updatedExpenceData = [...expenceData];
    updatedExpenceData.splice(index, 1);
    setExpenceData(updatedExpenceData);
  };

  const siteAdd = () => {
    navigate(`/addsite`);
  };

  const worktypeAdd = () => {
    navigate(`/addworktype`);
  };

  const siteengineer = () => {
    navigate(`/addmastersite`);
  };

  const expensepage = () => {
    console.log("route");
    navigate("/expense");
  };

  const reportpage = () => {
    console.log("route");
    navigate("/Reportpage");
  };
  const CategeryPage = () => {
    navigate("/Categery");
  };

  const logout = () => {
    showSuccess("Success!", "Logout Successfully!");
    navigate(`/`);
  };


useEffect (() => {
  Dailydata();
},[]);

 const Dailydata = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  let yyyy = today.getFullYear();
  let Todaydate = yyyy + '-' + mm + '-' + dd;
  let req = {
    Todaydate: Todaydate,
  };
  console.log("todatereq", req);
  callApi("POST", "expense_dateselect.php", req)
  .then((res:any) => res.data)
  .then((response) => {
    if (response["statusCode"] === 200) {
      let res = response.responseJson.data;
      setState((prevState) => ({
        ...prevState,
        ExpenseDaytable: res,
      }));
      console.log("RajuDaily---ExpenseEdit", ExpenseDaytable);
    }
  })
  .catch((err) => {
    console.log(err);
  });
}



const expensemodalclose = () => {
  setExpensemodal(false);
};

const EditExpense = (id: any) => {
  console.log("editExpense Id", id);
  setExpensemodal(true);
  let req = {
    reportId: id,
  };
  callApi("POST", "report_editselect.php", req)
    .then((res) => res.data)
    .then((response) => {
      if (response["statusCode"] === 200) {
        let res = response.responseJson.data[0];
        setExpenseeditData(res);
        console.log("setExpenseEditdata", expensedatadetails);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const updatExpense = (id: any) => {
  let req = {
    reportId: id,
    engineer_name: expensedatadetails.engineer_name,
    site_name: expensedatadetails.site_name,
    worktype: expensedatadetails.worktype,
    categery: expensedatadetails.categery,
    amount: expensedatadetails.amount,
    description: expensedatadetails.description,
  };
  console.log("shaliya---req", req);

  callApi("POST", "report_edit.php", req)
    .then((res) => res.data)
    .then((response) => {
      console.log("response", response);
      if (response["success"] === true) {
        showSuccess("Success!", "Expense Updated Successfully!");
        setExpensemodal(false);
        Dailydata();
      } else {
        showError("Failed", "Not Inserted !");
      }
    })
    .catch((err) => {
      console.log(err);
    });
};


const [expensedatadetails, setExpenseeditData] = useState({
    id: "",
    engineer_name: "",
    site_name: "",
    worktype: "",
    categery: "",
    amount: "",
    description: "",
});


const Editonchange = (e: any) => {
  const { name, value } = e.target;
  if (name === "engineer_name") {
    setExpenseeditData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  if (name === "site_name") {
    setExpenseeditData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  
  if (name === "worktype") {
    setExpenseeditData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  if (name === "categery") {
    setExpenseeditData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  if (name === "amount") {
    setExpenseeditData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  if (name === "description") {
    setExpenseeditData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  console.log("amount", name);
  console.log("amount", e.target.value);
};

const ExpensedeleteRow = (id: any) => {
  let req = {
    reportId: id,
  };
  callApi("POST", "report_delete.php", req)
    .then((res) => res.data)
    .then((response) => {
      console.log("response", response);
      if (response["success"] === true) {
        showSuccess("Success!", "Expense Deleted Successfully!");
        Dailydata();
      } else {
        showError("Failed", "Not Inserted !");
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const { ExpenseDaytable } = state;
  return (
    <main>
      <header>
        <div className="">
          <h1>Manage Expense</h1>
          <Dropdown className="d-inline mx-2 ddrop">
            <Dropdown.Toggle id="autoclose-true" className="dbutton">
              <FontAwesomeIcon icon={faGear} className="icon printicon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="padd" onClick={siteAdd}>
                {" "}
                <FontAwesomeIcon icon={faPlusCircle} className="icon" />
                Add Site
              </Dropdown.Item>
              <Dropdown.Item className="padd" onClick={CategeryPage}>
                {" "}
                <FontAwesomeIcon icon={faPlusCircle} className="icon" />
                Add Categery
              </Dropdown.Item>
              <Dropdown.Item className="padd" onClick={siteengineer}>
                {" "}
                <FontAwesomeIcon icon={faPlusCircle} className="icon" />
                Add Site Engineer
              </Dropdown.Item>
              <Dropdown.Item className="padd" onClick={worktypeAdd}>
                {" "}
                <FontAwesomeIcon icon={faPlusCircle} className="icon" />
                Add Worktype
              </Dropdown.Item>
              <Dropdown.Item className="padd" onClick={expensepage}>
                {" "}
                <FontAwesomeIcon icon={faPlusCircle} className="icon" />
                Add Expense
              </Dropdown.Item>
              <Dropdown.Item className="padd" onClick={reportpage}>
                {" "}
                <FontAwesomeIcon icon={faFileAlt} className="icon" />
                Report
              </Dropdown.Item>
              <Dropdown.Item className="padd" onClick={logout}>
                {" "}
                <FontAwesomeIcon icon={faSignOutAlt} className="icon" /> Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
      <h1></h1>
      <div className="dashcontiner">
        <Row className="scrollRow">
          <Col lg="10" md="10" className="flex">
       
          </Col>
          <Col lg="2" md="2" className="">
            <Button
              variant="outline-primary subbutton newbtn"
              onClick={addExpenceRow}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Add Expense
            </Button>
          </Col>
        </Row>

        <Row style={{ padding: "0% 3%" }} className="scrollRow">
          <Col lg="12" md="12" className="">
            <Table
              responsive="md lg"
              striped
              bordered
              hover
              style={{ width: "100% !important", textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Site Engineer</th>
                  <th>Site Name</th>
                  <th>Work Type</th>
                  <th>Categery</th>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>Add</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody id="expenseTableBody">
                {expenceData?.length > 0
                  ? expenceData.map((itm: any, index: any) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              name="engineer"
                              onChange={(e) => onchange(e, index)}
                            >
                              <option value="">Select Engineer</option>
                              {state.SiteEngineerslect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.engineername}
                                >
                                  {categery.engineername}
                                </option>
                              ))}
                            </Form.Select>
                            {isValid && itm.engineer == "" && (
                              <p
                                style={{
                                  margin: "0px 5px",
                                  textAlign: "left",
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              >
                                Required Field.
                              </p>
                            )}
                          </td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              name="selectsite"
                              onChange={(e) => onchange(e, index)}
                            >
                              <option value="">Select Site</option>
                              {state.SiteSelect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.sitename}
                                >
                                  {categery.sitename}
                                </option>
                              ))}
                            </Form.Select>
                            {isValid && itm.selectsite == "" && (
                              <p
                                style={{
                                  margin: "0px 5px",
                                  textAlign: "left",
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              >
                                Required Field.
                              </p>
                            )}
                          </td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              name="worktype"
                              onChange={(e) => onchange(e, index)}
                            >
                              <option value="">Select Worktype</option>
                              {state.WorktypeSelect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.worktype}
                                >
                                  {categery.worktype}
                                </option>
                              ))}
                            </Form.Select>
                            {isValid && itm.worktype == "" ? (
                              <p
                                style={{
                                  margin: "0px 5px",
                                  textAlign: "left",
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              >
                                Required Field.
                              </p>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              name="categery"
                              onChange={(e) => onchange(e, index)}
                            >
                              <option value="">Select Categery</option>
                              {state.CategerySelect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.categery_name}
                                >
                                  {categery.categery_name}
                                </option>
                              ))}
                            </Form.Select>
                            {isValid && itm.categery == "" && (
                              <p
                                style={{
                                  margin: "0px 5px",
                                  textAlign: "left",
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              >
                                Required Field.
                              </p>
                            )}
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder=""
                              name="amount"
                              minLength={5}
                              maxLength={50}
                              onChange={(e) => onchange(e, index)}
                              required
                            />
                            {isValid && itm.amount == "" && (
                              <p
                                style={{
                                  margin: "0px 5px",
                                  textAlign: "left",
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              >
                                Required Field.
                              </p>
                            )}
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              name="description"
                              minLength={5}
                              maxLength={50}
                              onChange={(e) => onchange(e, index)}
                              required
                            />
                            {isValid && itm.description == "" && (
                              <p
                                style={{
                                  margin: "0px 5px",
                                  textAlign: "left",
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              >
                                Required Field.
                              </p>
                            )}
                          </td>
                          <td>
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              className="icon"
                              style={{ fontSize: "25px", color: "blue" }}
                              onClick={addExpenceRow}
                            />
                          </td>
                          <td>
                            <FontAwesomeIcon
                              icon={faMinusCircle}
                              className="icon"
                              style={{ fontSize: "25px", color: "red" }}
                              onClick={() => deleteExpenceRow(index)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row style={{ padding: "0% 3%" }} className="scrollRow">
          <Col lg="10" md="10" className=""></Col>
          <Col lg="2" md="2" style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="outline-primary subbutton newbtn"
              onClick={SubmitHandleclick}
            >
              Submit
            </Button>
          </Col>
        </Row>

        <Row style={{ padding: "0% 3%" }} className="scrollRow">
  <Col lg="12" md="12" className="">
  {ExpenseDaytable && ExpenseDaytable.length > 0 ? (
    <h3 style={{ fontSize: "2rem", fontWeight: "bold", color: "#333", textAlign: "center", textTransform: "uppercase", borderBottom: "2px solid #333", paddingBottom: "0.5rem" }}>Today's Expenses</h3>
  ) : null} 
  </Col> 
</Row>
<br />
<Row style={{ padding: "0% 3%" }} className="scrollRow">
          <Col lg="12" md="12" className="">
          {ExpenseDaytable && ExpenseDaytable.length > 0 ? (
              <Table
                responsive="md lg"
                striped
                bordered
                hover
                style={{ width: "100% !important", textAlign: "center" }}
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date</th>
                    <th>Site Engineer</th>
                    <th>Site</th>
                    <th>Work Type</th>
                    <th>Categery</th>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody id="expenseTableBody">
                  {ExpenseDaytable &&
                    ExpenseDaytable.length > 0 &&
                    ExpenseDaytable.map((details: any, index: any) => {
                      return (
                        <>
                          <tr>
                            <td>
                                {index + 1}
                              </td>
                              <td>{details.date}</td>
                              <td>{details.engineer_name}</td>
                              <td>{details.site_name}</td>
                              <td>{details.worktype}</td>
                              <td>{details.categery}</td>
                              <td>{details.amount}</td>
                              <td>{details.description}</td>
                            <td>
                           
                                  <Dropdown className="d-inline mx-2">
                                    <Dropdown.Toggle
                                      id="dropdown-autoclose-true"
                                      className="actionbutton"
                                    >
                                      Action
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                       onClick={() => EditExpense(details.id)}
                                      >
                                        Edit
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        onClick={() =>
                                          ExpensedeleteRow(details.id)
                                        }
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
             ) : null} 
            </Col>
            </Row>
      </div>

      <Modal show={expenseSelectmodal} onHide={expensemodalclose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Site Engineer</label>
              <Form.Select
                aria-label="Default select example"
                name="engineer_name"
                onChange={Editonchange}
                value={expensedatadetails.engineer_name}
              >
                <option value="">Select Engineer</option>
                {state.SiteEngineerslect.map((categery: any) => (
                  <option key={categery.id} value={categery.engineername}>
                    {categery.engineername}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="labeldiv">
              <label className="label">Site </label>
              <Form.Select
                aria-label="Default select example"
                name="site_name"
                onChange={Editonchange}
                value={expensedatadetails.site_name}
              >
                <option value="">Select Site</option>
                {state.SiteSelect.map((categery: any) => (
                  <option key={categery.id} value={categery.sitename}>
                    {categery.sitename}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="labeldiv">
              <label className="label">Worktype </label>
              <Form.Select
                aria-label="Default select example"
                name="worktype"
                onChange={Editonchange}
                value={expensedatadetails.worktype}
              >
                <option value="">Select Worktype</option>
                {state.WorktypeSelect.map((categery: any) => (
                  <option key={categery.id} value={categery.worktype}>
                    {categery.worktype}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="labeldiv">
              <label className="label">Categery</label>
              <Form.Select
                aria-label="Default select example"
                name="categery"
                onChange={Editonchange}
                value={expensedatadetails.categery}
              >
                <option value="">Select Categery</option>
                {state.CategerySelect.map((categery: any) => (
                  <option key={categery.id} value={categery.categery_name}>
                    {categery.categery_name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="labeldiv">
              <label className="label">Amount</label>
              <input
                type="number"
                className="form-control"
                placeholder=""
                name="amount"
                minLength={5}
                maxLength={50}
                onChange={Editonchange}
                value={expensedatadetails.amount}
                required
              />
            </div>
            <div className="labeldiv">
              <label className="label">Description</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                name="description"
                minLength={5}
                maxLength={50}
                onChange={Editonchange}
                value={expensedatadetails.description}
                required
              />
            </div>
            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={() => updatExpense(expensedatadetails.id)}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </main>
  );
};
export default Expensepage;
