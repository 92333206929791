import axios from "axios";

// https://merlok.zerame.com/api/
//  export const API_ENDPOINT = " http://localhost/JMJbackend/";

 export const API_ENDPOINT = "https://projects.zerame.com/expense-report/JMJbackend/";



export async function callApi(method: string, path: string, data?: any) {
  if (method.toUpperCase() === "POST") {
	return await axios.post(API_ENDPOINT + path, data);
  } else if (method.toUpperCase() === "DELETE") {
	return await axios.delete(API_ENDPOINT + path);
  } else {
	return await axios.get(API_ENDPOINT + path);
  }
}
