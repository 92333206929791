import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./component/auth/login";
import Dashboard from "./component/auth/dashboard";
import AddSite from "./component/auth/addsite";
import Worktype from "./component/auth/addworktype";
import SideEngineer from "./component/auth/mastersite"
import Categery from "./component/auth/categery";
import Expensepage from "./component/auth/addexpense";
import Reportpage from "./component/auth/report"

const App = () => {

  
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/addsite" element={<AddSite />} />
          <Route path="/addworktype" element={<Worktype />} />
          <Route path="/addmastersite" element={<SideEngineer />} />
          <Route path="/Categery" element={< Categery />} />
          <Route path="/expense" element={< Expensepage />} />
          <Route path="/Reportpage" element={< Reportpage />} />


          
        </Routes>
      </Router>
      <Router basename="/dashboard">
</Router>

    </div>
  );
};

export default App;
