import {
  faArrowLeft,
  faSearch,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { showError, showSuccess } from "../../utills/sweetAlert";
import "./login.css";
import "./prj_details.css";
import React, { useEffect, useState } from "react";
import { callApi } from "../../utills/api";
import ExpenseExcelComponent from "./expencedataExcel";

const Reportpage = () => {
  const navigate = useNavigate();

  const [Fromdate, setFromdate] = React.useState("");
  const [Todate, setTodate] = React.useState("");
  const [selectsite, setSite] = React.useState("");
  const [categery, setCat] = React.useState("");

  const [FromdateError, setFromdateError] = React.useState("");
  const [TodateError, setTodateError] = React.useState("");
  const [SiteError, setSiteError] = React.useState("");
  const [Reportfilterdata, setReportData] = React.useState([]);

  const [expenseSelectmodal, setExpensemodal] = useState(false);

  const [state, setState] = useState({
    SiteSelect: [],
    CategerySelect: [],
    WorktypeSelect: [],
    SiteEngineerslect: [],
  });

  const [Expenseeditdetails, setExpenseEditdata] = useState({
    id: "",
    engineer_name: "",
    site_name: "",
    worktype: "",
    categery: "",
    amount: "",
    description: "",
  });

  const [siteengineerDetails, setsiteengineerDetails] = useState({
    id: "",
    worktype: "",
  });

  useEffect(() => {
    getSiteCatselect();
    console.log("siteengineerDetails:", siteengineerDetails);
  }, [siteengineerDetails.worktype]);

  const getSiteCatselect = () => {
    callApi("POST", "siteengineer_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("engineerselect", res.data.responseJson.data);
          setState((prevState) => ({
            ...prevState,
            SiteEngineerslect: res.data.responseJson.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    callApi("POST", "site_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("site_select", res.data.responseJson.data);
          setState((prevState: any) => ({
            ...prevState,
            SiteSelect: res.data.responseJson.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    callApi("POST", "worktype_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("worktype_select", res.data.responseJson.data);
          setState((prevState) => ({
            ...prevState,
            WorktypeSelect: res.data.responseJson.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    callApi("POST", "categery_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("categery_select", res.data.responseJson.data);
          setState((prevState: any) => ({
            ...prevState,
            CategerySelect: res.data.responseJson.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const back = () => {
    navigate(`/expense`);
  };
  const logout = () => {
    showSuccess("Success!", "Logout Successfully!");
    navigate(`/`);
  };

  const onchange = (e: any) => {
    const { name, value } = e.target;
    if (name === "Fromdate") {
      setFromdate(value);
      setFromdateError("");
      console.log("fromdate", e.target.value);
    } else if (name === "Todate") {
      setTodate(value);
      setTodateError("");
      console.log("todate", e.target.value);
    } else if (name === "selectsite") {
      setSite(value);
      console.log("eventselectsite", e.target.value);
    } else if (name === "categery") {
      setCat(value);
      console.log("eventcategery", e.target.value);
    }
  };

  const Editonchange = (e: any) => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;
    setExpenseEditdata((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
    console.log("Expenseeditdetails", Expenseeditdetails.engineer_name);
  };

  const searchExpense = (type: any) => {
    let FromdateError = "";
    let TodateError = "";
    if (!Fromdate) {
      FromdateError = "Fromdate is required";
    } else if (!Todate) {
      TodateError = "Todate is required";
    }
    if (FromdateError || TodateError || SiteError) {
      setFromdateError(FromdateError);
      setTodateError(TodateError);
      return;
    }
    let searchParams = JSON.parse(
      sessionStorage.getItem("searchParams") || "{}"
    );
    console.log("searchParams", searchParams);

    let req;
    if (type == "notsearch" && Object.keys(searchParams).length !== 0) {
      req = searchParams;
      console.log("sakthi");
    } else {
      req = {
        fromdate: Fromdate,
        todate: Todate,
        site_name: selectsite,
        categery: categery,
      };
      console.log("surya");
    }

    sessionStorage.setItem("searchParams", JSON.stringify(req));

    console.log("req", req);
    callApi("POST", "report_fillter.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["result"] === true) {
          let res = response.data;
          setReportData(res);
          console.log("setReportData", Reportfilterdata);
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const EditExpense = (id: any) => {
    console.log("editExpense Id", id);
    setExpensemodal(true);
    let req = {
      reportId: id,
    };
    callApi("POST", "report_editselect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] === 200) {
          let res = response.responseJson.data[0];
          setExpenseEditdata(res);
          console.log("setExpenseEditdata", Expenseeditdetails);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("useEffect SetExpenseData:", Expenseeditdetails);
  }, [Expenseeditdetails]);

  const updatExpense = (id: any) => {
    let req = {
      reportId: id,
      engineer_name: Expenseeditdetails.engineer_name,
      site_name: Expenseeditdetails.site_name,
      worktype: Expenseeditdetails.worktype,
      categery: Expenseeditdetails.categery,
      amount: Expenseeditdetails.amount,
      description: Expenseeditdetails.description,
    };
    console.log("req", req);

    callApi("POST", "report_edit.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Expense Updated Successfully!");
          setExpensemodal(false);
          searchExpense("notsearch");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const expensemodalclose = () => {
    setExpensemodal(false);
  };

  const ExpensedeleteRow = (id: any) => {
    let req = {
      reportId: id,
    };
    callApi("POST", "report_delete.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Expense Deleted Successfully!");
          searchExpense("notsearch");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <main>
      <header>
        <div className="">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="icon backicon"
            onClick={back}
          />
          <h1 className="heading">Manage Report</h1>
          <Dropdown className="d-inline mx-2 ddrop">
            <Dropdown.Toggle id="autoclose-true" className="dbutton">
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="icon printicon"
                onClick={logout}
              />
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </header>

      <div className="expensecon" style={{ marginTop: "2%" }}>
        <br />
        <Row className="mb-3">
          <Col lg="3" md="3" sm="3" className="colprjname">
            <label>From Date</label>
            <input
              type="date"
              className="form-control"
              placeholder="From Date"
              name="Fromdate"
              minLength={5}
              maxLength={50}
              onChange={onchange}
              required
            />
            <div
              className="text-danger"
              style={{ position: "absolute", marginTop: "4%" }}
            >
              {FromdateError}
            </div>
          </Col>
          <Col lg="3" md="3" sm="3" className="colprjname">
            <label>To Date</label>
            <input
              type="date"
              className="form-control"
              placeholder="To Date"
              name="Todate"
              onChange={onchange}
              minLength={5}
              maxLength={50}
              required
            />
            <div
              className="text-danger"
              style={{ position: "absolute", marginTop: "4%" }}
            >
              {TodateError}
            </div>
          </Col>
          <Col lg="3" md="3" sm="3" className="colprjname">
            <label>Site Name</label>
            <Form.Select
              aria-label="Default select example"
              name="selectsite"
              onChange={onchange}
            >
              <option value="">Select Site</option>
              {state.SiteSelect.map((categery: any) => (
                <option key={categery.id} value={categery.sitename}>
                  {categery.sitename}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col lg="3" md="3" sm="3" className="colprjname">
            <label>Categery </label>
            <Form.Select
              aria-label="Default select example"
              name="categery"
              onChange={onchange}
            >
              <option value="">Select Categery</option>
              {state.CategerySelect.map((categery: any) => (
                <option key={categery.id} value={categery.categery_name}>
                  {categery.categery_name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col lg="3" md="3" sm="3" className="colprjname">
            <Button
              variant="outline-primary subbutton newbtn"
              onClick={() => searchExpense("search")}
            >
              <FontAwesomeIcon icon={faSearch} className="icon" />
              Search
            </Button>
          </Col>
          {Reportfilterdata && Reportfilterdata.length > 0 ? (
            <Col lg="3" md="3" sm="3" className="colprjname">
              <ExpenseExcelComponent data={Reportfilterdata} />
            </Col>
          ) : null}
        </Row>

        <Row style={{ padding: "0% 3%" }} className="scrollRow">
          <Col lg="12" md="12" className="">
            {Reportfilterdata && Reportfilterdata.length > 0 ? (
              <Table
                responsive="md lg"
                striped
                bordered
                hover
                style={{ width: "100% !important", textAlign: "center" }}
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date</th>
                    <th>Site Engineer</th>
                    <th>Site</th>
                    <th>Work Type</th>
                    <th>Categery</th>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody id="expenseTableBody">
                  {Reportfilterdata &&
                    Reportfilterdata.length > 0 &&
                    Reportfilterdata.map((details: any, index: any) => {
                      return (
                        <>
                          <tr>
                            <td>
                              {Reportfilterdata.length - 1 != index && (
                                <>{index + 1}</>
                              )}
                            </td>
                              <td>{details.created_date}</td>
                              <td>{details.engineer_name}</td>
                              <td>{details.site_name}</td>
                              <td>{details.worktype}</td>
                              <td>{details.categery}</td>
                              <td>{details.amount}</td>
                              <td>{details.description}</td>
                            <td>
                              {Reportfilterdata.length - 1 != index && (
                                <>
                                  <Dropdown className="d-inline mx-2">
                                    <Dropdown.Toggle
                                      id="dropdown-autoclose-true"
                                      className="actionbutton"
                                    >
                                      Action
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => EditExpense(details.id)}
                                      >
                                        Edit
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        onClick={() =>
                                          ExpensedeleteRow(details.id)
                                        }
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
            ) : null}
          </Col>
        </Row>
      </div>

      <Modal show={expenseSelectmodal} onHide={expensemodalclose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Site Engineer</label>
              <Form.Select
                aria-label="Default select example"
                name="engineer_name"
                onChange={Editonchange}
                value={Expenseeditdetails.engineer_name}
              >
                <option value="">Select Engineer</option>
                {state.SiteEngineerslect.map((categery: any) => (
                  <option key={categery.id} value={categery.engineername}>
                    {categery.engineername}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="labeldiv">
              <label className="label">Site </label>
              <Form.Select
                aria-label="Default select example"
                name="site_name"
                onChange={Editonchange}
                value={Expenseeditdetails.site_name}
              >
                <option value="">Select Site</option>
                {state.SiteSelect.map((categery: any) => (
                  <option key={categery.id} value={categery.sitename}>
                    {categery.sitename}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="labeldiv">
              <label className="label">Worktype </label>
              <Form.Select
                aria-label="Default select example"
                name="worktype"
                onChange={Editonchange}
                value={Expenseeditdetails.worktype}
              >
                <option value="">Select Worktype</option>
                {state.WorktypeSelect.map((categery: any) => (
                  <option key={categery.id} value={categery.worktype}>
                    {categery.worktype}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="labeldiv">
              <label className="label">Categery</label>
              <Form.Select
                aria-label="Default select example"
                name="categery"
                onChange={Editonchange}
                value={Expenseeditdetails.categery}
              >
                <option value="">Select Categery</option>
                {state.CategerySelect.map((categery: any) => (
                  <option key={categery.id} value={categery.categery_name}>
                    {categery.categery_name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="labeldiv">
              <label className="label">Amount</label>
              <input
                type="number"
                className="form-control"
                placeholder=""
                name="amount"
                minLength={5}
                maxLength={50}
                onChange={Editonchange}
                value={Expenseeditdetails.amount}
                required
              />
            </div>
            <div className="labeldiv">
              <label className="label">Description</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                name="description"
                minLength={5}
                maxLength={50}
                onChange={Editonchange}
                value={Expenseeditdetails.description}
                required
              />
            </div>
            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={() => updatExpense(Expenseeditdetails.id)}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};
export default Reportpage;
