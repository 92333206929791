import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { Button, Col, Dropdown, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faGear,
  faPlusCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { callApi } from "../../utills/api";
import { showError, showSuccess } from "../../utills/sweetAlert";
import { useNavigate } from "react-router-dom";

interface Project {
  prjdesc: string;
  id: number;
  prjname: string;
}

const SideEngineer = () => {
  const navigate = useNavigate();
  const [siteengineermodal, setsiteengineermodal] = useState(false);
  const [siteengineerselectmodal, setsiteengineerselectmodal] = useState(false);
  const [siteengineer, setSiteengineer] = useState("");
  const [siteengineerError, setSiteengoneerError] = useState("");
  const [totalProjects, setTotalProjects] = useState<Project[]>([]);
  const [state, setState] = useState({
    SiteEngineertable: [],
  });
  const [selectcolor, setselectcolor] = useState({ id: "", engineername: ""});
  const addsiteengineermodal = () => setsiteengineermodal(true);
  const handlesiteengineerClose = () => {
    setsiteengineermodal(false);
  };
  const handleselectShow = () => setsiteengineerselectmodal(true);
  const siteengineermodalclose = () => {
    setsiteengineerselectmodal(false);
    setselectcolor({id: "", engineername: ""});
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "siteengineer") setSiteengineer(value);
    console.log("setWorktype",name);
    console.log("log",e.target.value);
    
    
  };

  const getProjectDetails = () => {
    callApi("POST", "siteengineer_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("siteengineer", res.data.responseJson.data);
          setState({ SiteEngineertable: res.data.responseJson.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addsiteEngineerClick = () => {
    let siteengineerError = "";
    if (!siteengineer) {
      siteengineerError = "Site Engineer  is required";
    } else if (!/^[a-zA-Z\s]+$/.test(siteengineer)) {
      siteengineerError = "Site Engineer must contain only alphabets and spaces";
    }
    if (siteengineerError) {
      setSiteengoneerError(siteengineerError);
      return;
    }
    setsiteengineermodal(false);
    setSiteengineer("");
    const req = {
      engineername: siteengineer,
    };
    callApi("POST", "siteengineer_insert.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Site Engineer Added Successfully!");
          getProjectDetails();
          setsiteengineermodal(false);
          setSiteengineer("");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const logout = () => {
    showSuccess("Success!", "Logout Successfully!");
    navigate(`/`);
  };


  useEffect(() => {
    getProjectDetails();
    console.log("selectcolor:", selectcolor);
  }, [selectcolor.engineername]);

  const SiteEngineereditmodal = (id: any) => {
    setsiteengineerselectmodal(true);
    console.log("SiteEngineer", id);
    let req = {
      engineerId: id,
    };

    callApi("POST", "siteengineer_editselect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          console.log("siteengineer_editselect", response.responseJson.data);
          let res = response.responseJson.data[0];
          setselectcolor(res);
          console.log("setselectcolor", selectcolor);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const back = () => {
    navigate(`/expense`);
  };

  const handleChangesiteengineer = (event: any) => {
    console.log("type", event);
    setselectcolor({"engineername": event.target.value, "id": selectcolor.id});
    console.log(" event.target.value", event.target.value);
  };

  const updatecolor = (id: any) => {
    let req = {
      engineerId: selectcolor.id,
      enginername: selectcolor.engineername,
    };
    console.log("req", req);
    
    callApi("POST", "siteengineer_editupdate.php", req)
    .then((res) => res.data)
    .then((response) => {
      console.log("response", response);
      if (response["success"] === true) {
        showSuccess("Success!", "Site Engineer Updated Successfully!");
        getProjectDetails();
        setsiteengineerselectmodal(false);
        setselectcolor({id: "", engineername: ""});
      } else {
        showError("Failed", "Not Inserted !");
      }
    })
    .catch((err) => {
      console.log(err);
    });

  };

  const deletesiteEngineer = (id: any) => {
    let req = {
      engineerId:id,
    };
    console.log("req", req);
    
    callApi("POST", "siteengineer_delete.php", req)
    .then((res) => res.data)
    .then((response) => {
      console.log("response", response);
      if (response["success"] === true) {
        showSuccess("Success!", "Site Engineer Deleted Successfully!");
        getProjectDetails();
        setselectcolor({id: "", engineername: ""});
      } else {
        showError("Failed", "Not Inserted !");
      }
    })
    .catch((err) => {
      console.log(err);
    });

  };
  const { SiteEngineertable } = state;
  return (
    <main>
      <header>
        <div className="">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="icon backicon"
            onClick={back}
          />
          <h1 className="heading">Manage Site Engineer</h1>
          <Dropdown className="d-inline mx-2 ddrop">
            <Dropdown.Toggle id="autoclose-true" className="dbutton">
              <FontAwesomeIcon icon={faSignOutAlt} className="icon printicon" onClick={logout} />
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </header>

      <div className="dashcontiner">
        <Row className="scrollRow">
          <Col lg="1" md="1" className=""></Col>
          <Col lg="3" md="3" className="">
            <Button
              variant="outline-primary subbutton newbtn"
              onClick={addsiteengineermodal}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Add Site Engineer
            </Button>
          </Col>
          <Col lg="6" md="6" className="flex"></Col>
        </Row>
        <Row style={{ padding: "0% 3%" }} className="scrollRow">
          <Col lg="1" md="1" className=""></Col>

          <Col lg="10" md="10" className="">
          {SiteEngineertable && SiteEngineertable.length > 0 ? (
            <Table
              responsive="md lg"
              striped
              bordered
              hover
              style={{ width: "100% !important",textAlign:"center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Site Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {SiteEngineertable &&
                  SiteEngineertable.length > 0 &&
                  SiteEngineertable.map((details: any, index: any) => {
                    return (
                      <>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{details.engineername}</td>
                          <td>
                            <Dropdown className="d-inline mx-2">
                              <Dropdown.Toggle
                                id="dropdown-autoclose-true"
                                className="actionbutton"
                              >
                                Action
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => SiteEngineereditmodal(details.id)}
                                >
                                  Edit
                                </Dropdown.Item>

                                <Dropdown.Item
                                  onClick={() =>deletesiteEngineer(details.id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </Table>
) : null}
          </Col>


          <Col lg="1" md="1" className=""></Col>
        </Row>
      </div>

      <Modal show={siteengineermodal} onHide={handlesiteengineerClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Site Engineer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label"> Add Site Engineer </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Site Engineer "
                name="siteengineer"
                minLength={5}
                maxLength={50}
                required
                onChange={handleChange}
                onFocus={() => setSiteengoneerError("")}
              />
              <div className="text-danger">{siteengineerError}</div>
            </div>

            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={addsiteEngineerClick}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={siteengineerselectmodal} onHide={siteengineermodalclose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Site Engineer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Site Engineer </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Site Engineer "
                name="siteengineer"
                minLength={5}
                maxLength={50}
                required
                onChange={handleChangesiteengineer}
                onFocus={() => setSiteengoneerError("")}
                value={selectcolor.engineername}
              />
              <div className="text-danger">{siteengineerError}</div>
            </div>

            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={() => updatecolor(selectcolor.id)}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default SideEngineer;
