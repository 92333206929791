import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { Button, Col, Dropdown, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faGear,
  faPlusCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { callApi } from "../../utills/api";
import { showError, showSuccess } from "../../utills/sweetAlert";
import { useNavigate } from "react-router-dom";

interface Project {
  prjdesc: string;
  id: number;
  prjname: string;
}

const AddSite = () => {
  const navigate = useNavigate();
  const [sitemodal, setsitemodal] = useState(false);
  const [siteselectmodal, setsiteselectmodal] = useState(false);
  const [site, setColor] = useState("");
  const [sitenameError, setsitenameError] = useState("");
  const [totalProjects, setTotalProjects] = useState<Project[]>([]);
  const [state, setState] = useState({
    Sitedetailstable: [],
  });
  const [selectcolor, setselectSite] = useState({ id: "", sitename: ""});
  const addsidemodal = () => setsitemodal(true);
  const handlesiteClose = () => {
    setsitemodal(false);
  };
  const handleselectShow = () => setsiteselectmodal(true);
  const sitemodalclose = () => {
    setsiteselectmodal(false);
    setselectSite({id: "", sitename: ""});
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "site") setColor(value);
    console.log("setcolor",name);
    console.log("log",e.target.value);
  };

  const getProjectDetails = () => {
    callApi("POST", "site_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("siteresponse", res.data.responseJson.data);
          setState({ Sitedetailstable: res.data.responseJson.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addsideClick = () => {
    let sitenameError = "";
    if (!site) {
      sitenameError = "Site name is required";
    } else if (!/^[a-zA-Z\s]+$/.test(site)) {
      sitenameError = "Site name must contain only alphabets and spaces";
    }
    if (sitenameError) {
      setsitenameError(sitenameError);
      return;
    }
    setsitemodal(false);
          setColor("");
    const req = {
      sitename: site,
    };
    callApi("POST", "site_insert.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Site Added Successfully!");
          getProjectDetails();
          setsitemodal(false);
          setColor("");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const logout = () => {
    showSuccess("Success!", "Logout Successfully!");
    navigate(`/`);
  };


  useEffect(() => {
    getProjectDetails();
    console.log("selectcolor:", selectcolor);
  }, [selectcolor.sitename]);

  const handleShow = (id: any) => {
    setsiteselectmodal(true);
    console.log("siteid", id);
    let req = {
      siteId: id,
    };

    callApi("POST", "site_editselect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          console.log("site_editselect", response.responseJson.data);
          let res = response.responseJson.data[0];
  
          setselectSite(res);
          console.log("editselect", selectcolor);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const back = () => {
    navigate(`/expense`);
  };

  const handlechengesite = (event: any) => {
    console.log("type", event);
    setselectSite({"sitename": event.target.value, "id": selectcolor.id});
    console.log(" event.target.value", event.target.value);
  };

  const updatecolor = (id: any) => {
    let req = {
      siteId: selectcolor.id,
      site_name: selectcolor.sitename,
    };
    console.log("req", req);
    
    callApi("POST", "site_editupdate.php", req)
    .then((res) => res.data)
    .then((response) => {
      console.log("response", response);
      if (response["success"] === true) {
        showSuccess("Success!", "Site Updated Successfully!");
        getProjectDetails();
        setsiteselectmodal(false);
        setselectSite({id: "", sitename: ""});
      } else {
        showError("Failed", "Not Inserted !");
      }
    })
    .catch((err) => {
      console.log(err);
    });

  };

  const deletecolor = (id: any) => {
    let req = {
      siteId:id,
    };
    console.log("req", req);
    
    callApi("POST", "site_delete.php", req)
    .then((res) => res.data)
    .then((response) => {
      console.log("response", response);
      if (response["success"] === true) {
        showSuccess("Success!", "Site Deleted Successfully!");
        getProjectDetails();
        setselectSite({id: "", sitename: ""});
      } else {
        showError("Failed", "Not Inserted !");
      }
    })
    .catch((err) => {
      console.log(err);
    });

  };
  const { Sitedetailstable } = state;
  return (
    <main>
      <header>
        <div className="">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="icon backicon"
            onClick={back}
          />
          <h1 className="heading">Manage Site</h1>
          <Dropdown className="d-inline mx-2 ddrop">
            <Dropdown.Toggle id="autoclose-true" className="dbutton">
              <FontAwesomeIcon icon={faSignOutAlt} className="icon printicon" onClick={logout} />
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </header>

      <div className="dashcontiner">
        <Row className="scrollRow">
          <Col lg="1" md="1" className=""></Col>
          <Col lg="3" md="3" className="">
            <Button
              variant="outline-primary subbutton newbtn"
              onClick={addsidemodal}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Add Site
            </Button>
          </Col>
          <Col lg="6" md="6" className="flex"></Col>
        </Row>
        <Row style={{ padding: "0% 3%" }} className="scrollRow">
          <Col lg="1" md="1" className=""></Col>

          <Col lg="10" md="10" className="">
          {Sitedetailstable && Sitedetailstable.length > 0 ? (
            <Table
              responsive="md lg"
              striped
              bordered
              hover
              style={{ width: "100% !important",textAlign:"center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Site Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Sitedetailstable &&
                  Sitedetailstable.length > 0 &&
                  Sitedetailstable.map((details: any, index: any) => {
                    return (
                      <>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{details.sitename}</td>
                          <td>
                            <Dropdown className="d-inline mx-2">
                              <Dropdown.Toggle
                                id="dropdown-autoclose-true"
                                className="actionbutton"
                              >
                                Action
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleShow(details.id)}
                                >
                                  Edit
                                </Dropdown.Item>

                                <Dropdown.Item
                                  onClick={() =>deletecolor(details.id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </Table>
            ) : null}
          </Col>

          <Col lg="1" md="1" className=""></Col>
        </Row>
      </div>

      <Modal show={sitemodal} onHide={handlesiteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Site</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label"> Add Site </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                name="site"
                minLength={5}
                maxLength={50}
                required
                onChange={handleChange}
                onFocus={() => setsitenameError("")}
              />
              <div className="text-danger">{sitenameError}</div>
            </div>

            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={addsideClick}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={siteselectmodal} onHide={sitemodalclose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Site</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Site Name </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Site Name"
                name="site"
                minLength={5}
                maxLength={50}
                required
                onChange={handlechengesite}
                onFocus={() => setsitenameError("")}
                value={selectcolor.sitename}
              />
              <div className="text-danger">{sitenameError}</div>
            </div>

            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={() => updatecolor(selectcolor.id)}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default AddSite;
