import React from 'react';
import { CSVLink } from 'react-csv';


interface DataItem {
    created_date: string;
    engineer_name: string;
    site_name:string;
    worktype: string;
    categery: string;
    amount: string;
    description: string;
}


interface Props {
    data: DataItem[];
}


const ExpenseExcelComponent: React.FC<Props> = ({ data }) => {
    const csvData = data.map((item :any, index :any) => {
       
        return {
            "Sl.No": index + 1,
            "Date":item.created_date,
            "Engineer": item.engineer_name,
            "Site":item.site_name,
            "Worktype":item.worktype,
            "Categery":item.categery,
            "Amount":item.amount,
            "Description":item.description
        };
    });

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

    return (
        <CSVLink data={csvData} className="btn btn-outline-success" filename={`JMJ Expense Report - ${formattedDate}.csv`}>
            Download Excel
        </CSVLink>
    );
};

export default ExpenseExcelComponent;
