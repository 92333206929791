import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { Button, Col, Dropdown, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faGear,
  faPlusCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { callApi } from "../../utills/api";
import { showError, showSuccess } from "../../utills/sweetAlert";
import { useNavigate } from "react-router-dom";

interface Project {
  prjdesc: string;
  id: number;
  prjname: string;
}

const Worktype = () => {
  const navigate = useNavigate();
  const [worktypemodal, setworktypemodal] = useState(false);
  const [worktypeselectmodal, setworktypeselectmodal] = useState(false);

  const [worktypeError, setworktypeError] = useState("");
  const [amountError, setamountError] = useState("");
  const [descError, setDescError] = useState("");

  const [totalProjects, setTotalProjects] = useState<Project[]>([]);
  const [state, setState] = useState({
    Worktypetable: [],
  });
  const [workTypedetails, setWorktypedetails] = useState({
    id: "",
    worktype: "",
    amount: "",
    description: "",
  });
  const addworktypemodal = () => setworktypemodal(true);
  const handleworktypeClose = () => {
    setworktypemodal(false);
  };
  const handleselectShow = () => setworktypeselectmodal(true);
  const worktypemodalclose = () => {
    setworktypeselectmodal(false);
    setWorktypedetails({ id: "", worktype: "", amount: "", description: "" });
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "worktype") {
      setWorktypedetails(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    if (name === "amount") {
      setWorktypedetails(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    
    if (name === "description") {
      setWorktypedetails(prevState => ({
        ...prevState,
        [name]: value
      }));
    }

    console.log("amount", name);
    console.log("amount", e.target.value);
};

  const getWorktypeDetails = () => {
    callApi("POST", "worktype_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("worktype_select.php", res.data.responseJson.data);
          setState({ Worktypetable: res.data.responseJson.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addworktypeClick = () => {
    let worktypeError = "";
    let amountError = "";
    let descError = "";
    if (!workTypedetails.worktype) {
      worktypeError = "Work type is required";
    } else if (!/^[a-zA-Z\s]+$/.test(workTypedetails.worktype)) {
      worktypeError = "Work type must contain only alphabets and spaces";
    }

    if (!workTypedetails.amount) {
      amountError = "Amount is required";
    }
    if (!workTypedetails.description) {
      descError = "Description is required";
    }

    if (worktypeError || amountError || descError) {
      setworktypeError(worktypeError);
      setamountError(amountError);
      setDescError(descError);
      return;
    }

    setworktypemodal(false);
    setWorktypedetails({
      id: "",
      worktype: "",
      amount: "",
      description: "",
    });
    const req = {
      worktype: workTypedetails.worktype,
      amount:workTypedetails.amount,
      description: workTypedetails.description,
    };
    callApi("POST", "worktype_insert.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Worktype Added Successfully!");
          getWorktypeDetails();
          setworktypemodal(false);
          setWorktypedetails({
            id: "",
            worktype: "",
            amount: "",
            description: "",
          });
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const logout = () => {
    showSuccess("Success!", "Logout Successfully!");
    navigate(`/`);
  };

  useEffect(() => {
    getWorktypeDetails();
    console.log("workTypedetails:", workTypedetails);
  }, [workTypedetails.worktype]);

  const workTypeeditmodal = (id: any) => {
    setworktypeselectmodal(true);
    console.log("worktypeid", id);
    let req = {
      worktypeId: id,
    };

    callApi("POST", "worktype_editselect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          console.log("worktype_idselect", response.responseJson.data);
          let res = response.responseJson.data[0];
          setWorktypedetails(res);
          console.log("setselectwork", workTypedetails);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const back = () => {
    navigate(`/expense`);

  };

  const handlechangeworktype = (event: any) => {
    console.log("type", event);
    setWorktypedetails({
      id: workTypedetails.id,
      worktype: event.target.value,
      amount: workTypedetails.amount,
      description:workTypedetails.description
    });
    console.log(" event.target.value", event.target.value);
  };

  const updateworkType = (id: any) => {
    let req = {
      worktypeId: workTypedetails.id,
      worktype: workTypedetails.worktype,
      amount: workTypedetails.amount,
      description: workTypedetails.description
    };
    console.log("req", req);

    callApi("POST", "worktype_editupdate.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Worktype updated Successfully!");
          getWorktypeDetails();
          setworktypeselectmodal(false);
          setWorktypedetails({
            id: "",
            worktype: "",
            amount: "",
            description: "",
          });
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteworkType = (id: any) => {
    let req = {
      worktypeId: id,
    };
    console.log("req", req);

    callApi("POST", "worktype_delete.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Worktype Deleted Successfully!");
          getWorktypeDetails();
          setWorktypedetails({
            id: "",
            worktype: "",
            amount: "",
            description: "",
          });
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { Worktypetable } = state;
  return (
    <main>
      <header>
        <div className="">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="icon backicon"
            onClick={back}
          />
          <h1 className="heading">Manage Worktype</h1>
          <Dropdown className="d-inline mx-2 ddrop">
            <Dropdown.Toggle id="autoclose-true" className="dbutton">
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="icon printicon"
                onClick={logout}
              />
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </header>

      <div className="dashcontiner">
        <Row className="scrollRow">
          <Col lg="1" md="1" className=""></Col>
          <Col lg="3" md="3" className="">
            <Button
              variant="outline-primary subbutton newbtn"
              onClick={addworktypemodal}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Add Work
            </Button>
          </Col>
          <Col lg="6" md="6" className="flex"></Col>
        </Row>
        <Row style={{ padding: "0% 3%" }} className="scrollRow">
          <Col lg="1" md="1" className=""></Col>

          <Col lg="10" md="10" className="">
          {Worktypetable && Worktypetable.length > 0 ? (

            <Table
              responsive="md lg"
              striped
              bordered
              hover
              style={{ width: "100% !important", textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Work Type</th>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Worktypetable &&
                  Worktypetable.length > 0 &&
                  Worktypetable.map((details: any, index: any) => {
                    return (
                      <>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{details.worktype}</td>
                          <td>{details.amount}</td>
                          <td>{details.description}</td>
                          <td>
                            <Dropdown className="d-inline mx-2">
                              <Dropdown.Toggle
                                id="dropdown-autoclose-true"
                                className="actionbutton"
                              >
                                Action
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => workTypeeditmodal(details.id)}
                                >
                                  Edit
                                </Dropdown.Item>

                                <Dropdown.Item
                                  onClick={() => deleteworkType(details.id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </Table>
              ) : null}
          </Col>
            

          <Col lg="1" md="1" className=""></Col>
        </Row>
      </div>

      <Modal show={worktypemodal} onHide={handleworktypeClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Worktype</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label"> Add Worktype </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Worktype"
                name="worktype"
                minLength={5}
                maxLength={50}
                required
                onChange={handleChange}
                onFocus={() => setworktypeError("")}
              />
              <div className="text-danger">{worktypeError}</div>
            </div>

            <div className="labeldiv">
              <label className="label"> Amount </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Amount"
                name="amount"
                minLength={5}
                maxLength={50}
                required
                onChange={handleChange}
                onFocus={() => setamountError("")}
              />
              <div className="text-danger">{amountError}</div>
            </div>

            <div className="labeldiv">
              <label className="label"> Description </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter description"
                name="description"
                minLength={5}
                maxLength={50}
                required
                onChange={handleChange}
                onFocus={() => setDescError("")}
              />
              <div className="text-danger">{descError}</div>
            </div>

            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={addworktypeClick}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={worktypeselectmodal} onHide={worktypemodalclose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Worktype</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Worktype </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Worktype"
                name="worktype"
                minLength={5}
                maxLength={50}
                required
                onChange={handlechangeworktype}
                onFocus={() => setworktypeError("")}
                value={workTypedetails.worktype}
              />
              <div className="text-danger">{worktypeError}</div>
            </div>

            <div className="labeldiv">
              <label className="label"> Amount </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Amount"
                name="amount"
                minLength={5}
                maxLength={50}
                required
                onChange={handleChange}
                onFocus={() => setamountError("")}
                value={workTypedetails.amount}
              />
              <div className="text-danger">{amountError}</div>
            </div>

            <div className="labeldiv">
              <label className="label"> Description </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter description"
                name="description"
                minLength={5}
                maxLength={50}
                required
                onChange={handleChange}
                onFocus={() => setworktypeError("")}
                value={workTypedetails.description}
              />
              <div className="text-danger">{worktypeError}</div>
            </div>

            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={() => updateworkType(workTypedetails.id)}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default Worktype;
